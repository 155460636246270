import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import { useArticles } from '../../hooks';

import Link from '../common/Link';

import styles from './styles/blogFooter.module.scss';

const propTypes = {
    currentPostId: PropTypes.string,
};

const defaultProps = {
    currentPostId: null,
};

const BlogFooterPosts = ({ currentPostId }) => {
    const { t } = useTranslation();

    const articleData = useArticles();
    const filteredPosts = articleData
        .filter(item => item.postId !== currentPostId)
        .slice(0, 4);

    return (
        <section id={styles.quadColSection}>
            <h2 id={styles.quadColTitle}>
                {t('siteMetadata.articles.moreArticles')}
            </h2>
            <ul id={styles.quadColList}>
                {filteredPosts.map(article => (
                    <Link
                        to={article.link}
                        key={article.postId}
                    >
                        <li className={styles.item}>
                            <div className={styles.itemThumb}>
                                <GatsbyImage
                                    image={article.imageFooterList}
                                    alt={article.thumbnailAlt}
                                    className={styles.itemIcon}
                                />
                            </div>
                            <div className={styles.itemText}>
                                <h3 className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: article.titleList }} />
                                <p className={styles.itemParagraph} dangerouslySetInnerHTML={{ __html: article.subtitleListShort || article.subtitleList }} />
                                <p className={styles.itemLearnMore}>
                                    {t('siteMetadata.articles.readMore')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={styles.chevronRight}
                                    />
                                </p>
                            </div>
                        </li>
                    </Link>
                ))}
            </ul>
        </section>
    );
}

BlogFooterPosts.propTypes = propTypes;
BlogFooterPosts.defaultProps = defaultProps;

export default BlogFooterPosts;
