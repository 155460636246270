/* eslint-disable */

module.exports = {
    siteUrl: `https://www.witbe.net`, // Site domain. Do not include a trailing slash!

    postsPerPage: 7, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Witbe - Quality of Experience Technology`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Witbe is the leader of Quality of Experience technology. We test & monitor any service, through any network, on any device`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Witbe`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.svg`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest

    // added
    twitterUsername: '@witbe'
}
