import React from 'react';
import PropTypes from 'prop-types';

import Link from '../common/Link';

import { formatDateWithOrdinal } from '../../utils'

import styles from './styles/blogSidebar.module.scss';

const propTypes = {
    date: PropTypes.string,
    topics: PropTypes.arrayOf(PropTypes.string),
    industries: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    date: null,
    topics: [],
    industries: [],
    tags: [],
};

const BlogSidebarTags = ({ date, topics, industries, tags }) => (
    <React.Fragment>
        {date && (
            <div className={styles.listsContainerRecentPosts}>
                <h3 className={styles.sidebarSubHeader}>Publication date</h3>
                <div className={styles.sidebarItemTagBox}>
                    <div className={styles.sidebarItemTagDate}>
                        <p>{formatDateWithOrdinal(date)}</p>
                    </div>
                </div>
            </div>
        )}
        <div className={styles.listsContainerRecentPosts}>
            <h3 className={styles.sidebarSubHeader}>Published topic</h3>
            <div className={styles.sidebarItemTagBox}>
                {topics.map(item => (
                    <Link key={item.id} to='/articles/' state={{ topic: item }}>
                        <div key={item.id} className={styles.sidebarItemTag}>
                            <p>{item}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        <div className={styles.listsContainerRecentPosts}>
            <h3 className={styles.sidebarSubHeader}>Industry</h3>
            <div className={styles.sidebarItemTagBox}>
                {industries.map(item => (
                    <Link key={item.id} to='/articles/'>
                        <div key={item.id} className={styles.sidebarItemTag}>
                            <p>{item.name || item}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        {tags.length > 0 && (
            <div className={styles.listsContainerRecentPosts}>
                <h3 className={styles.sidebarSubHeader}>Tags</h3>
                <div className={styles.sidebarItemTagBoxButton}>
                    {tags.map(item => (
                        <Link key={item.id} to='/articles/' state={{ searchProp: item.name || item }}>
                            <div key={item.id} className={styles.sidebarItemTagButton}>
                                <p>{item.name || item}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        )}

    </React.Fragment>
);

BlogSidebarTags.propTypes = propTypes;
BlogSidebarTags.defaultProps = defaultProps;

export default BlogSidebarTags;
