/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { useViewport } from '../hooks';

import { Helmet } from 'react-helmet'
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import SEO from '../components/common/SEO';

import BlogSidebar from '../components/blog/BlogSidebar';
import BlogSidebarTags from '../components/blog/BlogSidebarTags';
import BlogSidebarPosts from '../components/blog/BlogSidebarPosts';
import SubscribeBlueBoxModal from '../components/common/SubscribeBlueBoxModal';
import BlogFooterPosts from '../components/blog/BlogFooterPosts';

import styles from './styles/blogArticle.module.scss'
import newStyles from './styles/blogpost.module.scss';

/**
* Single post view (/:slug)
*
* This file renders a single Ghost post and loads all the content.
*
*/
const GhostArticle = ({ data, location, pageContext }) => {
    const post = data.ghostPost
    // const createdAtDate = post.created_at
    // const readingTime = post.reading_time
    // const authorName = post.primary_author.name
    // const authorImage = post.primary_author.cover_image
    // const authorBio = post.primary_author.bio
    const metaDescription = post?.meta_description
    const metaTitle = post?.meta_title
    const imgWithoutPath = post?.feature_image.slice(21)
    const body = post?.html

    const { id, bodyTitle, tags, topics, industries, mainTopic } = pageContext;

    const { isSmallerMobileBreakpoint } = useViewport();

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
                title={metaTitle}
            />
            <SEO
                title={metaTitle}
                description={metaDescription}
                image={imgWithoutPath}
                article="article"
                ogDescription={metaDescription}
            />
            <Helmet>
                <style type="text/css">{`${post?.codeinjection_styles}`}</style>
            </Helmet>
            <Layout title={metaTitle} isEvents>
                <div id={styles.unitImageWrapper}>
                    <img src={post?.feature_image} id={styles.bannerImg} alt={bodyTitle} />
                </div>
                <section id={newStyles.articlesContainer}>
                    <div className={newStyles.articlesMain}>
                        <section className={newStyles.articlesContainer}>
                            <h1 className={newStyles.bodyTextHeader} dangerouslySetInnerHTML={{ __html: bodyTitle }} />
                            {isSmallerMobileBreakpoint && <BlogSidebarTags id={id} tags={tags} topics={topics} industries={industries} />}
                            <div className={styles.bodyText} dangerouslySetInnerHTML={{ __html: body }} />
                            {isSmallerMobileBreakpoint && <BlogSidebarPosts id={id} category={mainTopic} /> }
                        </section>
                    </div>
                    {!isSmallerMobileBreakpoint && <BlogSidebar id={id} mainTopic={mainTopic} tags={tags} topics={topics} industries={industries} />}

                </section>
                {isSmallerMobileBreakpoint ? <SubscribeBlueBoxModal /> : <BlogFooterPosts />}
            </Layout>
        </>
    )
}

GhostArticle.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}


export default GhostArticle;

export const postQuery = graphql`
    query GhostPost ($slug: String) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields,
            primary_author {
                name
            }
        },
        allGhostPost {
            edges {
                node {
                    id
                    url
                    title
                    feature_image
                    created_at
                    slug
                    tags {
                        name
                        id
                        url
                    }
                }
            }
        },
    }
`
