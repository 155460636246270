import React from 'react';
import PropTypes from 'prop-types';

import SubscribeBlueBoxModal from '../common/SubscribeBlueBoxModal';

import BlogSidebarTags from './BlogSidebarTags';
import BlogSidebarPosts from './BlogSidebarPosts';

import styles from '../../templates/styles/blogpost.module.scss';

const propTypes = {
    id: PropTypes.string,
    mainTopic: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    topics: PropTypes.arrayOf(PropTypes.string),
    industries: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    id: '',
    mainTopic: '',
    tags: [],
    topics: [],
    industries: [],
};

const BlogSidebar = ({
    id,
    mainTopic,
    tags,
    topics,
    industries,
}) => (
    <aside className={styles.sidebar}>
        <div className={styles.listsContainer}>
            <BlogSidebarTags id={id} tags={tags} topics={topics} industries={industries} />
            <SubscribeBlueBoxModal />
            <BlogSidebarPosts id={id} category={mainTopic} />
        </div>
    </aside>
);

BlogSidebar.propTypes = propTypes;
BlogSidebar.defaultProps = defaultProps;

export default BlogSidebar;
